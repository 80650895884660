// {swiper}
  .swiper {
    width: 100%;
    height: 70vh;
    // overflow: visible;
  }
  
  .swiper-wrapper {
    
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
    // background: white;
    transition: all .3s ease;
    
    &.swiper-slide-active {
      // background: linear-gradient(135deg, #000000e6, #000000);
      // background: linear-gradient(45deg, rgb(246, 146, 89), rgb(241, 105, 117));
      transform: scale(1.4);

      z-index: 2
    }
  }
  
  /* ARROWS */
 
  /* PAGINATION */
  .swiper-pagination-bullet {
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    font-size: 12px;
    color:#000;
    opacity: 1;
    background: rgba(0,0,0,0.2);
    
    &.swiper-pagination-bullet-active {
      color:#fff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      
      // background: linear-gradient(45deg, rgb(246, 146, 89), rgb(241, 105, 117));
    }
  }
  
  .head-swiper{
    // overflow: hidden;
  }
  
 .swiper-slide>img{
  width: 250px;
  }
  .swiper-button-next:after, .swiper-rtl .swiper-button-prev:after {
    content: 'next';
    color: black ;
}
.swiper-button-prev:after, .swiper-rtl .swiper-button-next:after {
  content: 'prev';
  color: black;
}
@media only screen and (max-width: 900px) {


.swiper {
  width: 100%;
  height: 280px;
  overflow: hidden;
}

  .img-g {
    padding: 0px !important;

  }
  .swiper-border-1>h1 {
    cursor: pointer;
    font-size: 12px;
}
.swiper-border-1>h1 {
  width: 170px;
}
.swiper-border-1>p {
  font-size: 10px;
}
.episode-p-h-2 {
  width: 90%;
}
.swiper-border-1 {
  border: 1px solid;
  display: flex;
  gap: 1rem;
  padding: 8px 7px;
  flex-direction: column;
  background: white;
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
  align-items: center;
}
.swiper-slide-active{
  // width: 216.333px !important;
}
}

.swiper-data1{
//  padding: 100px 0px;
}


@mixin object-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

$circleSize: 165px;
$radius: 100px;
$shadow: 0 0 10px 0 rgba(255,255,255,.35);
$fontColor: rgb(250,250,250);

.profile-pic {
  width: fit-content;
  color: transparent;
  transition: all .3s ease;
  @include object-center;
  position: relative;
  transition: all .3s ease;
  
  input {
    display: none;
  }
  
  img {
    position: absolute;
    object-fit: cover;
    width: $circleSize;
    height: $circleSize;
    box-shadow: $shadow;
    border-radius: $radius;
    z-index: 0;
    
    border: 3px solid #fff;
    max-width: 170px;
    box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;

  }
  
  .-label {
    cursor: pointer;
    height: $circleSize;
    width: $circleSize;
  }
  
  &:hover {
    .-label {
      @include object-center;
      background-color: rgba(0,0,0,.8);
      z-index: 10000;
      color: $fontColor;
      transition: background-color .2s ease-in-out;
      border-radius: $radius;
      margin-bottom: 0;
    }
  }
  
  span {
    display: inline-flex;
    padding: .2em;
    height: 2em;
    gap: 10px;
  }
}

/////////////////////////
// Body styling 🐾
/////////---------->


